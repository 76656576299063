<template>
	<a-table class="white-table" :columns="columns" :data-source="data" :rowKey="(e) => e.order">
		<span slot="order" slot-scope="obj">
			{{ obj.order + 1 }}
		</span>
		<span slot="payout" slot-scope="obj">
			<span v-if="obj.date">{{ obj.date }} Days</span>
			<span v-else>{{ obj.stage }} stage</span>
		</span>
		<span slot="amount" slot-scope="obj">
			<span v-if="!obj.amount.perc">${{ formatNumber(obj.amount.value) }}</span>
			<span v-else>{{ obj.amount.value }}%</span>
		</span>
		<div slot="action" slot-scope="obj" class="dF aC">
			<a-icon type="edit" class="cursor-pointer hover:text-primary" @click="$emit('edit', obj)" />
			<i class="fa fa-trash ml-3 cursor-pointer hover:text-primary" @click="deleteThis(obj)" />
		</div>
	</a-table>
</template>

<script>
import { formatNumber } from "bh-mod";
export default {
	props: {
		data: {
			default: () => [],
			type: Array,
		},
	},
	data() {
		return {
			columns: [
				{
					title: "Order",
					key: "order",
					scopedSlots: { customRender: "order" },
				},
				{
					title: "Payout",
					key: "payout",
					scopedSlots: { customRender: "payout" },
				},
				{
					title: "Payout Amount",
					key: "payoutAmount",
					scopedSlots: { customRender: "amount" },
				},
				{
					title: "Action",
					key: "action",
					scopedSlots: { customRender: "action" },
				},
			],
		};
	},
	methods: {
		formatNumber,
		deleteThis(e) {
			this.$confirm({
				title: `Delete Payout`,
				content: (h) => (
					<div>Do you want to delete Commission Payout Structure #{e.order + 1}?</div>
				),
				okText: "DELETE",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk: () => {
					let commissionPayout = this.data
						.filter((x) => x.order !== e.order)
						.map((x, xI) => ({ ...x, order: xI }));
					this.$store.commit("SET_OPTIONS", {
						where: "commissionPayoutStructure",
						what: commissionPayout,
					});
				},
			});
		},
	},
};
</script>

<style>
</style>
